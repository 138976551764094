.home-explore-sec.about2-sec.apply-sec{
	@media #{$not-phone}{
		height: fit-content;
		min-height: unset;

		.vc_col-sm-4{
			width: fit-content;
		}
		.vc_col-sm-8{
			padding-bottom: 20px;
		}
	}
	@media #{$not-desktop}{
		

		.vc_col-sm-8{
			padding-top: 40px;
		}
		height: fit-content;
		min-height: 360px;
	}

	&:before{
		content: none;
	}

	h4{
		font-size: 35px;
		margin-bottom: 0 !important;
	}
	h2{
		font-size: 60px;
		font-weight: 400;
		margin-bottom: 15px !important;
	}

	.vc_column_container:last-child li{
		font-size: 18px !important;
	}
}

.career-sec{
	
	margin-top: 50px;

	h2.vc_custom_heading.custom-title{
		margin-bottom: 40px;

		&:after{
			content: none;
		}
	}

	.vc_col-sm-6{
		background: var(--color__grey5);
		margin-bottom: 20px;
		@media #{$not-phone}{
			width: 49%;

			&:first-child{
				margin-right: 1%;
			}
			&:last-child{
				margin-left: 1%;
			}
		}
	}

}


.intern-sec{
	padding-top: 0 !important;
	.intern-title{
		font-weight: 300;
	    background: var(--color__grey5);
	    color: var(--color__grey);
	    padding: 10px 15px;
	    font-size: 20px;
	}
	.wpb_text_column{
		padding-left: 15px;
		padding-right: 15px;
	}
}
	.intern-btn a.vc_general {
	    font-family: "futura-m";
	    font-size: 16px !important;

	    &:hover{
			background-color:var(--color__grey) !important;
			
		}
	}

@import "faq";

.resource-sec{
	.resource-row{
		margin-bottom: 40px;
	}
	@media #{$not-phone}{

		p{
			display: flex;
			justify-content: space-between;
		}
		span.download{
			float: right;
			color: var(--color__red);
		}
	}
	.intern-title{
		margin-bottom: 0;
	}
	.wpb_text_column{
		margin: 0;
    	padding: 10px 15px;	
    	border-bottom: 1px solid var(--color__grey3);

    	&:last-child{
    		border-bottom: none;
    	}
	}
	// .download a{
	// 	background: #ca6e80;
	//     color: white;
	//     padding: 5px 10px;
	//     margin: 5px;
	// }

}


.media-sec {
	.vc_tta-panel-heading{
		background: var(--color__green) !important;
		border: none;
		a{
			color: white !important;
			font-weight: 300;
		}
		i:before{
			border-color: white !important;
		}
	}
	.vc_row.wpb_row.vc_inner.vc_row-fluid{
		background: var(--color__grey5);
	    margin-bottom: 10px;
	    padding: 20px;
	    padding-bottom: 10px;
	}
	
	h4.vc_custom_heading{
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 17px !important;
	}

	.vc_tta-panel-body{
		padding: 15px 0 !important;
		margin-top: 30px;

		@media #{$not-phone}{
			overflow: visible !important;
			margin: 30px 15px 0;
		}
	}
	.vc_col-sm-6{
		background: var(--color__grey5);
		padding: 20px;
		height: 280px;
		margin-bottom: 40px;
		transition: box-shadow 0.3s ease-out;

		&:hover{
			box-shadow: 5px 8px 30px;
			transition: box-shadow 0.3s ease-out;
		}

		@media #{$not-phone}{
			width: 48%;

			&:first-child{
				margin-right: 2%;
			}
			&:last-child{
				margin-left: 2%;
			}
		}

		&>.vc_column-inner>.wpb_wrapper{
			display: table;
			height: 250px;
		}
	}
	.date{
		// margin-bottom: 20px;
		color: var(--color__grey6);
		// margin-right: 30px;
		// margin-top: 30px;
	}
	.download{
		margin-bottom: 10px;
		display: table-row;
		vertical-align: bottom;
		height: 30px;

		p{
			display: flex;
			color: var(--color__grey6);
			.link{
				margin-left: 20px;
			}
		}
	}
}

.contact-detail-sec{

		
		.detail-row{
			i.fa.fa-home{
				color: var(--color__black);
			}	
		@media #{$not-phone}{	
				width: 70%;
				margin-left: auto;
				margin-right: auto;
			}
		}


	td{

		border:none;
		text-align: left;
		font-family: futura-m;
		color: var(--color__black);
		padding-bottom: 0;
		// line-height: 20px;

		&:first-child{
		font-size: 0;
		vertical-align: top;
		width: 20px;
		padding-top: 6px;

			i{
				font-size: 16px;
				display: block;
				text-align: center;
			}
		}


		p.subtitle {
		    margin-bottom: 10px;
		}
	}

}

	.detail-row:last-child{
		td:first-child{
			padding-top: 10px;
		}
	}

	@media #{$not-phone}{
		width: 80%;
		margin: auto;
	}

	h6.small-title{
		font-size: 12px !important;
		font-weight: 300;
		margin-bottom: 5px;
	}

.open-sec{
	margin: 50px 0;
	
	p{
		color: var(--color__black);
	}
}