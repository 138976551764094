   /* ####################################################################################

                                    POSTCSS

/* ####################################################################################*/
:root{
    --color__red: #ca6e80;
    --color__red2: #c14e42;
    --color__black: #17252a;
    --color__green: #3a8e88;
    --color__grey: #6d6e71;
    --color__grey2: #9d9fa1;
    --color__grey3: #d0d1d1;
    --color__grey4: #b0b2b3;
    --color__grey5: #dedfdf;
    --color__grey6: #a9abad;
    --color__black2: #231f20;
};

$phone: "only screen and (max-width: 474px)";
$not-phone: "only screen and (min-width: 768px)";
$not-desktop: "only screen and (max-width: 767px)";
$larger-991: "only screen and (min-width: 992px)";
$smaller-menu: "only screen and (max-width: 1024px)";
$larger-menu: "only screen and (min-width: 1025px)";

@font-face{
    font-family: 'futura-b';
    src: url('http://gtwhi.com.my/wp-content/themes/sydney/fonts/futur.ttf');
}
@font-face{
    font-family: 'futura-i';
    src: url('http://gtwhi.com.my/wp-content/themes/sydney/fonts/Futura Bold Italic font.ttf');
}
@font-face{
    font-family: 'futura-m';
    src: url('http://gtwhi.com.my/wp-content/themes/sydney/fonts/futura medium bt.ttf');
}