.protect {
	margin-top: 20px;
	h5{
		margin-bottom: 15px;
		color: var(--color__grey);
	}
	.wpb_text_column{
		margin-bottom: 45px;
	}

}

.vc_section.festival-sec{
	@media #{$not-phone}{
		width: 90%;
		margin: auto;
		margin-top: 30px;
	}
	margin-top: 30px;
	.festival{
		padding-bottom: 30px;
		margin-bottom: 60px;
		border-bottom: 1px solid var(--color__grey2);

		&:last-child{
			border: none;
		}

		.wpb_single_image{
			margin-top: 20px;
			text-align: right;
			position: relative;
			&:before{
			    content: '';
			    position: absolute;
			    width: 110%;
			    height: 30px;
			    background: #939597;
			    left: -15px;
			    bottom: -10px;
			}
			figure{
				width: 90%;
				position: relative;
			}
		}
		.wpb_column:last-child{
			@media #{$not-phone}{
				padding-left: 50px;
			}
		}
		.title-special{
			tr{

			}
			td{
			    text-align: left;
			    border: none;
			    width: fit-content;
			    font-size: 28px;
			    font-family: futura-m;
			    vertical-align: text-bottom;
			    line-height: 30px;
			    color: var(--color__black);
			}
		}
	}
}

.extinguisher.img-box{
	.wpb_single_image{
		margin-bottom: 10px;
		.vc_single_image-wrapper{
			background: linear-gradient(transparent 60%, var(--color__grey2) 60%);
		}
	}
	.wpb_text_column{
		text-align: center;
		color: var(--color__red);
	}
}

.brochure {
    padding: 50px 0;
    text-align: center;
    background: var(--color__grey5);
}