   /* ####################################################################################

                                    ABOUT

/* ####################################################################################*/
.about-grid{
    margin-top: 30px;
    margin-bottom: 40px;

    @media #{$phone}{
    	margin-bottom: 100px;
    }
}

.about-logo {
    margin-bottom: 20px;

    .wpb_single_image img {
        max-width: 300px;
    }
}

.home-explore-sec.about1-sec{
	height: 30vw;
	background-attachment: fixed;
}

   /* ####################################################################################

                                    ABOUT 2

/* ####################################################################################*/
.home-explore-sec.about2-sec{
	height: 30vw;

	&:before{
		@media #{$phone}{
		    background: linear-gradient(rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 85%);
		}
		}


	h2{
		margin-bottom: 5px;
	}

	h4{
		font-size: 17px;
		color:white;
		margin-top: 0;
		font-weight: 300;
	}
	p{
		display: none;
	}
}

.about2-sec2{
	margin-bottom: 50px;
	h3{
		font-weight: 400;
		margin-bottom: 10px;
		margin-top: 30px;
	}
}
h3.custom-title2{
		font-weight: 400;
		margin-bottom: 10px;
		margin-top: 30px;
	}
.custom-link{
	text-align: center;
	margin-top: 20px;
}

span.highlight {
    color: var(--color__red);
}

a.underline{
	text-decoration: underline;

	&:hover{
		text-decoration: underline;
	}
}

.property-sec {
	@media#{$not-phone}{
		padding-bottom: 100px;
	}
	
	h2.vc_custom_heading.custom-title{
		margin-top: 20px;
	}
	.wpb_single_image{
		// margin-top: 30px;
		margin-bottom: 60px;
	}
	
		@media#{$phone}{
			padding: 10px;
		}
	}


.img-box .vc_single_image-wrapper{
		// padding: 30px;
		margin-top: 30px;
		// background: linear-gradient(transparent 30%,var(--color__grey2)30%);
}
.about2-sec3 {
	.wpb_single_image{

		@media #{$not-phone}{
			position: relative;
			top: -125px;
			margin-bottom: -40px;
		}

		img{
			@media#{$phone}{
				max-height: 300px;
				width: auto;
			}
		}
	}
}

h2.vc_custom_heading.custom-title.left-line:after{
	left: 15px;
	bottom: 40px;
	transform: none;
}

   /* ####################################################################################

                                    DIRECTORS

/* ####################################################################################*/

.directors{
	.director-single{
		img {
			padding: 25px;
			background: linear-gradient(white 20%,var(--color__red) 20%);
		}

		h3{
			font-size: 25px;
			margin-bottom: 5px;
			font-weight: 300;
		}
		h5{
			margin-top: 0;
			font-size: 20px;
			font-weight: 300;
		}
		.wpb_text_column{
			text-align: center;
		}
	}

	.director-grid{
		img {
			padding: 15px;
			background: linear-gradient(white 20%,var(--color__red) 20%);
		}

		h3{
			font-size: 16px;
			margin-bottom: 5px;
			font-weight: 600;
		}
		h5{
			margin-top: 0;
			font-size: 16px;
			font-weight: 300;
		}
		.wpb_text_column{
			text-align: center;
		}
		.wpb_single_image{
			margin-bottom: 20px;
		}

		.vc_column_container>.vc_column-inner{
			@media #{$not-phone}{
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}

.team-grid{
	.vc_gitem-animated-block{

		.vc_gitem-zone-mini{
			padding-right: 18%;

			.vc_gitem_row{
				z-index: 9999;
			}
				figure{
					max-width: 80px;
				}
			}

			.wpb_single_image.wpb_content_element{
				margin-bottom: 0;
			}
			.vc_custom_heading {
			    margin-bottom: 40px;
			}
			.vc_gitem-zone-b{
				transition: opacity 0.3s;
			}
			.vc_gitem-zone-a:before{
				content: '';
			    width: 90%;
			    height: 83%;
			    position: absolute;
			    border: 5px solid white;
			    z-index: 999;
			    top: 50%;
			    transform: translateY(-50%);
			    left: -5px;
			}
		}
	}




.fancybox-is-open .fancybox-bg{
	background: #17252a;
}
.fancybox-stage {
	color:white;

	p {
	    color: white;
	    font-family: futura-m;
	    font-size: 16px;
	    text-align: left;
	}
	h5{
		color: white;
		font-family: futura-m;
		font-weight: 400;
		margin-bottom: 0;
		font-size: 22px;
	}
	span{
		display: block;
	}
	img,
	.popuptitlemaincont,
	.popuptitlecont{
		display: none;
		background: transparent;
	}
	.fancybox-slide>*{
		background: transparent;
	}
	.fancybox-close-small:after{
		color: white;
	}
}

.pop-item.singletestimonialcont{
	min-height: unset;

	@media #{$not-phone}{
		padding: 0 15px 15px 0;
	}

	.popup-wrapper{
		position: relative;
		&:before{
			content: '';
		    position: absolute;
		    width: 100%;
		    height: 0%;
		    background: var(--color__grey3);
		    left: 0;
		    transition: all 0.3s;
		}

		&:hover:before{
			height: 100%;
			transition: all 0.3s;
		}
		&:hover p.testimonial-client-name{
			color: white;
			transition: color 0.3s;
		}
		&:hover p.testimonial-text{
			opacity: 1;
			transition: opacity 0.3s;
		}

		&:after{
			content: '';
		    position: absolute;
		    width: 90%;
		    height: 80%;
		    /* background: red; */
		    top: 20px;
		    border: 4px solid white;
		    border-right: none;
		    border-bottom: 0;
		    right: 0;
		}
	}

	a.various {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    z-index: 999;
	}

	.cs_readmore+p {
	    display: none;
	}
	
	p.testimonial-client-name{
		position: absolute;
		bottom: 10px;
		left: 25px;
		margin-bottom: 0;
		font-weight: 400;
		font-family: futura-m;
		color: var(--color_black2);
		transition: color 0.3s;
	}
	p.testimonial-text{
		position: absolute;
		margin: 0;
		top: 40px;
		left: 45px;
		font-family: futura-m;
		text-align: left;
		color: var(--color__grey);
		opacity: 0;
		transition: opacity 0.3s;
		font-size: 15px;
		padding-right: 10px;

		span{
			display: block;
			line-height: 17px;
			margin-bottom: 20px;
		}
	}
	.cs_readmore{
		display: none;
	}
}

.post-template.post-template-post_nosidebar .content-area {
    max-width: 1170px;
}
.col-md-12.popup-post {
    padding: 0;
}

.partner-row{
	@media #{$not-phone}{
		.partner-grid .vc_grid-item {
		    // width: 20%;
		    margin-bottom: 30px;
		}
	}

	.partner-grid{

		.vc_gitem-zone.vc_gitem-zone-a{
			border: 1px solid var(--color__grey4);

			@media #{$phone}{
				height: 200px;
			}

			.wpb_single_image{
				margin: 0;

				img {
				    max-height: 130px;
				    width: auto;
				    max-width: 160px;
				}
			}
		}
		.vc_gitem-zone-c .vc_gitem-col{
			// background-color: var(--color__grey4); 
			padding: 5px;
			
			.vc_gitem-post-data-source-post_title{
				margin: 0;
				color: var(--color_grey);
				font-family: futura-m;
				line-height: 20px;
				font-size: 15px;
				text-transform: uppercase;

				@media #{$not-phone}{
					min-height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					
				}
			}
		}
	}
	h3{
		font-weight: 400;
	}
}

