.vc_row.dhobi-1 p {
    font-size: 18px;
    line-height: 26px;
}

.vc_section.media-sec .vc_col-sm-6{
	height: inherit;
	padding-top: 0;
}
.widget-area .widget ul li a {
    text-transform: uppercase;
}

.fancybox-slide .fancyboxcont{
	width: 100%;
}

.extinguisher-row{
	a{
		color: var(--color__grey) !important;
	}
}

.search{
	#secondary{
		display: none; 
	}
	#primary{
		width: 100%;
	}
}
.post-date{
    text-align: center;
    padding-bottom: 20px;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    position: relative;

    &:after{
    	content: '';
	    width: 50px;
	    height: 1px;
	    background: var(--color__grey);
	    position: absolute;
	    bottom: 0;
	    left: 50%;
	    transform: translateX(-50%);
    }
}

@media screen and (min-width: 768px){
.tra-big .vc_single_image-wrapper img {
    max-height: 500px !important;
    width: auto;
}
.ele-2 .tra-big .vc_single_image-wrapper img {
    max-height: 400px !important;
    width: auto;
}
}

.vc_row.trader-split{
	max-width: 850px;
    margin: auto;
}

.anchor-img .vc_figure img{
	max-height: 500px;
	width: auto;
}

.bg-cover.search-cover{
	background: url("http://gtwhi.com.my/wp-content/uploads/2019/03/home-cover.jpg");
	opacity: 1 !important;
	background-repeat: no-repeat;
    background-size: cover;
    height: 25vw;
}

.search.search-results{
	.page .entry-header{
		display: block !important;
		margin-bottom: 5px;

		h2{
			font-size: 20px;
		}
	}
	h2.title-post.entry-title{
		font-size: 20px;
	}
	.page-header h3{
		font-weight: 400;
	}
	.posts-navigation .nav-previous{
		padding-left: 0;
	}
	#primary{
		padding-bottom: 40px;
	}
}

.single-post,.archive{
	#secondary{
		display: none;
	}

	#primary{
		width: 100%;
	}
}

.green-grid1 .vc_custom_heading a {
    text-shadow: 0px 0px 5px black;
    text-transform: uppercase;
}