.conservation{
	.vc_col-sm-6:last-child .wpb_text_column:last-child{
		margin-top: 50px;

			p{
				margin-bottom: 10px;
			}

	}
	ol{
		padding: 0;
		padding-left: 15px;
		margin-bottom: 20px;

		li{
			font-family: futura-m;
		}
	}
}
.compatible {
    margin-bottom: 50px;
}

section.vc_section.property-sec.role-sec {
    padding-top: 50px;
    padding-bottom: 50px;

    .img-box .vc_single_image-wrapper{
    	background: linear-gradient(transparent 50%, var(--color__grey2) 50%);
    }
}

section.art-sec{
	.img-box .vc_single_image-wrapper{
    	background: linear-gradient(transparent 70%, var(--color__grey2) 70%);
    }
}

.shophouse-col{
	h4{
		margin-bottom: 5px !important;
	}
	h2{
		color: white;
		margin-top: 0;
		margin-bottom: 24px !important;
	}
}

.shophouse-char-sec{
	.wpb_text_column{
		
		margin-bottom: 20px;
		p{
			color: var(--color__black2);
		}
		ul{
			padding-left: 15px;
		}
	}
	padding-bottom: 50px;
}



.shophouse-feature-sec{
	.img-box .vc_single_image-wrapper{
		padding-top: 0;
		background: linear-gradient(transparent 50%, var(--color__grey2) 50%);
	}

	padding-top: 50px;
	padding-bottom: 50px;

}

.shophouse-history-sec{
	p{
		color:var(--color__black2);
	}
	padding-top: 50px !important;
	padding-bottom: 50px;

	.wpb_text_column{
		margin-bottom: 10px;
	}
	 .vc_col-sm-6:first-child{
	 	border-right: 1px solid var(--color__grey4);

	 	@media #{$phone}{
	 		padding-bottom: 40px;
	 		margin-bottom: 40px;
	 		border-bottom: 1px solid var(--color__grey4);
	 	}
	 }

	 @media #{$phone}{
	 	.wpb_single_image.wpb_content_element{
	 		text-align: center;
	 	}
	 }

	 .history-row {
	    padding: 40px 0;
	    border-bottom: 1px solid var(--color__grey4);
	}
	.history-desc{
		width: 90%;
	    margin: auto !important;
	    padding-top: 30px;

	    p{
	    	color: var(--color__grey);
	    }
	    
		}
}

.contractor{ 
	margin-top: 30px;
	.vc_column-inner> .wpb_wrapper {
		@media #{$not-phone}{
		    column-count: 2;
		}
	    .wpb_text_column{
	    	position: relative;
	    	margin-bottom: 25px;
	    	padding-bottom: 25px;
	    	
	    	@media #{$not-phone}{
	    		width: 80%;
	    		margin: auto;
	    		margin-bottom: 25px;
	    	}

	    	&:after{
	    		content: '';
			    position: absolute;
			    width: 60px;
			    height: 5px;
			    background: var(--color__green);
			    bottom: 0;
	    	}
	    }

	    @media #{$not-phone}{
	    .wpb_text_column:nth-child(6),
	    .wpb_text_column:last-child{
	    	&:after{
	    		content: none;
	    	}
	    }
	}
	}
}

.oral-sec{
	h2{
		font-weight: 400;
		margin-bottom: 40px;
	}
	.vc_col-sm-2>.vc_column-inner>.wpb_wrapper>.wpb_single_image>figure{
		position: relative;
	&:after{
		    content: '';
		    position: absolute;
		    width: 100%;
		    height: 0%;
		    background: var(--color__grey3);
		    z-index: 9;
		    transition: height 0.3s ease-out;
		    left: 0;
		    top: 0;
	}

	img{
		@media #{$phone}{
			max-height: 250px;
			width: auto;
		}
	}
}
@media #{$not-phone}{
	.vc_col-sm-2:hover {
		
		.vc_column-inner>.wpb_wrapper>.wpb_single_image>figure:after{
			height: 100%;
		}
	
		.oral-pdf{
			opacity: 1;
		}
	}
}
	.vc_column_container>.vc_column-inner{
		padding: 0 15px 15px 0;
		
		.wpb_wrapper{
			position: relative;


			.wpb_content_element{
				margin-bottom: 0;
				color: white;

				p{
					font-size: 12px;

				}

				figure.vc_figure:before {
				    content: '';
				    position: absolute;
				    width: 90%;
				    height: 75%;
				    top: 12px;
				    border: 4px solid white;
				    border-right: none;
				    border-bottom: 0;
				    right: 0;
				}
			}
		}
	}

	.oral-name{
		// position: absolute;
		// bottom: 10px;
		// left: 20px;
		z-index: 99;
		text-align: center;
    	margin-top: 10px;
    	padding-bottom: 20px;

    	&:after{
    		content: 'view pdf';
		    color: var(--color__black);
		    font-size: 12px;
		    font-family: futura-m;
		    color: var(--color__red);
    	}

		
		p{
			line-height: 13px;
			// text-shadow: 1px 1px 3px black;
			color: var(--color__black)
		}
	}
	.oral-num{
		position: absolute;
		top: 20px;
		right: 10px;
		z-index: 99;
	}
	.oral-pdf{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		opacity: 0;
		z-index: 99;
		transition: opacity 0.3s ease-out;

		p{
			margin-top: 5px;
		}

		&:before{
			content: '';
		    width: 50px;
		    height: 50px;
		    background: url(http://gtwhi.com.my/wp-content/uploads/2019/03/pdf.png);
		    position: absolute;
		    top: -100%;
		    left: 50%;
		    transform: translate(-50%,-50%);
		}

		@media #{$not-desktop}{
			position: relative;
			opacity: 1;

			&:before{
				content: none;
			}

			p{
				margin-top: 0;
			}
		}
	}
}

section.vc_section.object-sec {
    margin: 20px 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color__grey3);

    &:last-child{
    	border-bottom: none;
    }
}