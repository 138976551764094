.arconix-faq-title.faq-closed:after {
    content: '\f067 ';
    font-family: FontAwesome;
    float: right;
    padding: 5px;
    font-size: 10px;
}
.arconix-faq-title.faq-open:after {
    content: '\f068 ';
    font-family: FontAwesome;
    float: right;
    padding: 5px;
    font-size: 10px;
}
.wpb_wrapper .arconix-faq-title{
    color: var(--color__grey);
    font-family: futura-m;
    background: var(--color__grey5)!important;
    padding: 10px 15px;
    font-weight: 700;
}


.wpb_wrapper .arconix-faq-wrap{
    border-radius: 0;
    padding: 0;
    border: none;
    margin: 5px 0;
    }

.wpb_wrapper .arconix-faq-content{
    margin-left: 0;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}
.wpb_wrapper .arconix-faq-term-title{
    font-size: 16px;
    font-weight: 300;
}
