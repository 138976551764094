.wpb-js-composer {
	.vc_row.shop-sec{
		.vc_tta-color-grey.vc_tta-style-classic{ 
			.vc_tta-tab{
			border-right: 1px solid var(--color__black);

			&.vc_active>a{
				color: var(--color__green);
			}
			&:last-child{
				border: none;
			}
			&>a {
				    background: none;
				    border: none;
				    padding: 0 20px;
				    transition: all 0.3s ease-out;

				    &:hover{
				    	color: var(--color__green);
				    	transition: all 0.3s ease-out;
				    }
				}
			}


			.vc_tta-tabs-list{
				text-align: center;
			}
			.vc_tta-panels-container{
				margin-top: 30px;
			}
			.vc_tta-panels{
				background: none;
				border: none;
			}
			.book-title{
				margin-bottom: 20px;

				h5{
					margin-top: 0;
					font-weight: 500; 
				}
			}
			.book-spec{
				color: var(--color__black);
				margin-bottom: 20px;
			}
		}
	}
}

.vc_row.book-list{
	@media only screen and (min-width: 768px){
		.vc_gitem-animated-block.vc_gitem-animate {
		    width: 23.5%;
		}

		.vc_gitem-zone.vc_gitem-zone-c {
		    width: 72%;

		    .vc_col-sm-12{
		    	padding: 0;

		    	.vc_gitem-post-data-source-post_title{
		    		margin-top: 0;

		    		h4{
		    			margin-top: 0;
		    			font-size: 20px;
		    			font-weight: 500;
		    		}
		    	}
		    	.book-spec{
					color: var(--color__black2);
		    	}
		    }
		}
		.vc_grid-item.vc_grid-item-zone-c-right{
			padding-bottom: 40px;
		    margin-bottom: 60px;
		    border-bottom: .5px solid rgba(0,0,0,0.5);

		    &:last-child{
				border: none;
		    }
		}

	}
}

.home-explore-sec.about2-sec.order-sec {
	p{
		display: block;
		color: var(--color__grey);
	}
	ol{
		padding-left: 10px;
	}
	h2{
		margin-bottom: 0px !important;
	}
}

.merchandise-list{
	h5{
		font-weight: 600;
		    line-height: 20px;
		    font-size: 16px;
		    color: var(--color__grey) !important;
		// text-shadow: 1px 1px 3px black;
	}
	p{
		// text-shadow: 1px 1px 3px black;
		color: var(--color__grey) !important;
		font-size: 14px;
		font-weight: 600;
	}
	.vc_gitem-zone.vc_gitem-zone-a{
		background-size: 100% auto;
		background-color: #eceded;
	}

}


.merchandise-list.postcard{
	.vc_gitem-zone.vc_gitem-zone-a{
		background-size: 100% auto;
		background-color: var(black);
	}
}

.shop-nav{
	p{
		text-align: center;
		a{
			padding: 0 20px;
			border-right: 1px solid var(--color__black2);
			color: var(--color__black2);

			&:hover{
				color: var(--color__green);
			}

			&:last-child{
				border-right: none;
			}

			&.active{
				color: var(--color__green);
				pointer-events: none;
			}

			@media #{$phone}{
				display: block;
				border: none;
				margin-bottom: 10px;
			}
		}
	}
}

.book-grid{
	.pop-item.singletestimonialcont{
		text-align: center;
    	min-height: 450px;

    	.popup-wrapper{
    		min-height: 420px;
    		background: #eeeeee;

    		&:hover{
    			    box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
    		}

    		&:hover:before{
    			content: none;
    		}
    		&:after{
    			content: none;
    		}

    		.book-spec{
    			position: absolute;
			    bottom: 0;
			    left: 50%;
			    transform: translate(-50%,-50%);
			    width: 100%;
    		}
    		p.testimonial-client-name{
    			padding: 0 10px;
    		}

    		@media #{$not-desktop}{
    			min-height: unset;

    			.book-spec{
    				position: relative;
    				margin-top: 20px;

    				&:after{
    					content: 'Read More';
    					color: var(--color__red);
    				}
    			}
    		}
    	}

	}
}

section.vc_section.order-sec {
	margin-bottom: 0;
	.vc_row.wpb_row{
	position: relative;
}
}