   /* ####################################################################################

                                    POSTCSS

/* ####################################################################################*/
@import "variables";

   /* ####################################################################################

                                    TEMPLATE

/* ####################################################################################*/

footer#colophon {
    padding: 0px;
}

p.inspiren {
    /*bottom footer text formatting*/
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 0;
        float: left;
    padding-top: 6px;
}

.site-info.container {
    font-size: 12px;
    padding: 15px 0;
}
.site-info.container>.inspiren{
	font-size: 12px;
}
@media only screen and (min-width: 768px) {
    a.hoverInspiren {
        float: right;
    }
}

@media only screen and (max-width: 767px) {
    p.inspiren {
        text-align: center;
    }
    .reserve {
        display: block;
    }
}

.hoverInspiren:hover span {
    /*inspiren change color*/
    color: #ffe500;
    transition: all 0.3s;
}


.contact_table td {
    /*formating table*/
    padding: 0px;
    margin: 0px;
    text-align: left;
    border-collapse: collapse;
    border: 0px;
    color: white;
    font-size: 14px;
}


.entry-header h1,
.single .entry-header,
.page .entry-header {
    display: none !important;
}

.entry-footer {
    /*hide footer widget*/
    display: none;
}

.sidebar-column.col-md-3 {
    line-height: 20px;
}

#sidebar-footer h3.widget-title {
    padding-bottom: 20px;
}


.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"],
.contact-form textarea {
    border-color: #004896;
    background-color: rgba(0,0,0,0);
    color: black;
    padding: 4px 10px;
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    font-family: 'myriadpro';
}
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"]{
    height: 40px;
}
.contact-form textarea {
    height: 80px;
}
.contact-form form.wpcf7-form {
    width: 100%;
    margin: auto;
}

textarea.wpcf7-form-control.wpcf7-textarea::-webkit-input-placeholder,

/*placeholder color to white*/

input.wpcf7-form-control.wpcf7-text::-webkit-input-placeholder {
    /*placeholder color to white*/
    color: #004896 !important;
}

.contact-form input[type="reset"],
.contact-form input[type="submit"] {
    padding: 4px 50px 3px;
    border-radius: 20px;
    background: rgba(0,0,0,0);
    border: 1px solid #004896;
    font-weight: 300;
    float: right;
    color: #004896;
    font-family: 'myriadpro';
}


.contact-form input[type="reset"]:hover,
.contact-form input[type="submit"]:hover{ 
    background-color: #004896;
    color: white;
    transition: color 0.3s;
    transition: background-color 0.3s;
}

.bg-cover {
    background-repeat: no-repeat;
    height: 40vw;
    background-position: center;
    background-size: cover;
    min-height: 300px;
    // background-attachment: fixed;
}

@media only screen and (min-width: 768px) {
    .contact-form input[type="text"],
    .contact-form input[type="email"],
    .contact-form input[type="tel"],
    .contact-form textarea {
        /*format text box */
        width: 100%;
    }
}

@media only screen and (max-width: 1024px){
#masthead .col-md-4.col-sm-8.col-xs-12 {
    width: 90%;
    text-align: left;
    margin-bottom: 0;
}
    #masthead .btn-menu {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
}
    #masthead .col-md-8 {
    position: absolute;
    right: 0;
}
    #masthead .row {
    display: flex;
    align-items: center;
}
nav#mainnav-mobi.mainnav {
    top: 30px;
    z-index: 99999;
}
}


   /* ####################################################################################

                                    OVERALL

/* ####################################################################################*/

body p{
    font-weight: 300;
    line-height: 22px;
    /*letter-spacing: 1px;*/
    font-family: futura-m;
    font-size: 16px;
    // text-align: justify;s
}

h1,h2,h3,h4,h5,h6{
    font-family: futura-m !important;
}

header#masthead{
    top: 0;
    z-index: 9999999;
}
.header-clone {
    display: none;
}

.header-top-wrap {
    background: black;
    color: white;
    font-size: 11px;
}
.header-top p {
    display: inline;
        padding-left: 10px;
}
.header-top i {
    margin-right: 5px;
}

.top-detail {
    width: fit-content;
    float: right;
}

#content .container.content-wrapper {
    padding-top: 0;
    padding-bottom: 0;
}


#masthead .header-wrap .menu-item a {
    // font-weight: 300;
    font-size: 15px;
    font-family: futura-m;
    transition: all 0.3s;
    text-transform: uppercase;
}
#masthead .header-wrap .menu-item .sub-menu a{
    text-transform: capitalize;
}
#masthead .header-wrap .menu-item a:hover {
    // font-weight: 600;
    transition: all 0.3s;
}

#masthead .header-wrap .menu-item.current_page_item a {
    font-weight: 600;
    font-family: futura-i;
    color: var(--color__red);
}
div#sidebar-footer{
    background: rgba(0,0,0,0);
}
.sidebar-column.col-md-6:last-child,
div#sidebar-footer {
    padding: 0;
}
.sidebar-column h5 {
    color: white;
    margin-bottom: 10px;
}
#sidebar-footer .widget_text {
    padding: 0;
    margin-bottom: 30px;
}
#sidebar-footer .sidebar-column img {
    padding: 30px 0;
    // background: white;
    position: relative;
    top: -1px;
}
.sidebar-column .textwidget.custom-html-widget p {
    margin-bottom: 10px;
}
.sidebar-column i {
    width: 30px;
    /* text-align: center; */
}
#masthead.site-header {
    background-color: rgba(23,37,42,1);
    padding: 0;
}
.mainnav li{
    margin-bottom: 0;  
} 

@media only screen and (min-width: 1025px){
.header-wrap .col-md-4.col-sm-8.col-xs-12,
.header-wrap .col-md-8.col-sm-4.col-xs-12{
    width: fit-content;
}

.header-wrap .col-md-4.col-sm-8.col-xs-12:before {
    content: '';
    height: 155px;
    width: 100%;
    background: var(--color__black);
    position: absolute;
    top: -20px;
    left: 0;
}
.header-wrap .col-md-4.col-sm-8.col-xs-12 a {
    position: relative;
    top: 50px;
}

}

@media only screen and (min-width: 991px){
    #sidebar-footer .container{
        display: flex;
        padding-right: 0;
    }
}

@media only screen and (max-width: 767px){
    .top-detail{
        width: 100%;
        float: none;
        padding: 10px 0;
    }
    .header-top p {
    display: block;
    margin-bottom: 0;
    line-height: 20px;
}
    }

header#masthead:after {
    content: '';
    position: absolute;
    height: 90px;
    width: 100%;
    background: linear-gradient(white,transparent);
    z-index: 9;
}
.header-wrap {
    .col-md-4.col-sm-8.col-xs-12{
        position: relative;
        z-index: 99;
    }
    .container>.row{
        height: 60px;
    }
    .container{
        position: relative;
    }
    
}

// #masthead.site-header.float-header {
//     position: static !important;
// }

@media #{$larger-991}{
    .header-wrap .col-md-8.col-sm-4.col-xs-12{
            flex-grow: 1;

            #mainnav {
            width: 100%;

            .menu{
                display: flex;
                justify-content: space-between;

                li{
                    padding: 0;
                }
            }
        }
        }

        .header-wrap .container{
            padding-right: 0;
        }
    }
.header2 {
    position: absolute;
    z-index: 9999;
    right: 0;
    display: flex;
    align-items:center;
    margin-top: 5px;

    @media #{$smaller-menu}{
        right: 10px;
    }

    @media #{$phone}{
        right: 50%;
        transform: translateX(50%);
        .translation{
            width: 100px;
        }
        label{
            margin-bottom: 0;
        }
    }

    a{
        color: black;
        font-family: futura-m;
        font-weight: 400;
    }

    .wrapper{
        float: right;
    }
}

.search-bar {
    // input.search-submit{
    //     display: none;
    // }

    input.search-field{
        height: 30px;
        margin-left: 20px;
        font-family: futura-m;
        font-weight: 300;
        background: transparent;
        border: 1px solid #17252a;
    }

    input::-webkit-input-placeholder{
        font-family: futura-m;
        font-weight: 300;
        color: #17252a;
    }

    input.search-submit{
        font-family: fontAwesome;
    }

}

img.site-logo {
    max-width: 210px;
    padding-right: 20px;

     @media #{$not-desktop}{
        max-width: 130px;
     }
}
img.site-logo2{
    @media #{$not-desktop}{
        max-width: 90px;
    }
    @media #{$not-phone}{
        max-width: 165px;
    }
}

@media #{$larger-menu}{
#mainnav .sub-menu {
    width: 300px;

    a{
        width: 300px;
        line-height: 22px !important;
    }
}
}

ul li, ol li{
    font-family: futura-m;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
}

ul, ol{
    padding-left: 20px;
}


.split {

    @media #{$not-phone}{
        .vc_col-sm-6:first-child{
            padding-right: 5%;
        }
        .vc_col-sm-6:last-child{
            padding-left: 5%;
        }
}
}

#content .breadcrumb-container.theme5{
    position: absolute;
    top: 44vw;
    // left: 0;
    z-index: 9999;
    margin-left: 0;

    @media #{$smaller-menu}{
        top: 48.5vw;
    }

    @media #{$not-desktop}{
        top: 400px;
    }
    
    a::after,
    a::before{
        content: none;
    }
    ul>li::after{
        content: "\f0da";
        font-family: fontAwesome;
    }
    ul>li:last-child::after{
        content: none;
    }

    ul>li:hover span{
        color: var(--color__red);
    }
    .separator{
        margin: 0;
    }
    li{
        margin: 0;
        
        a{
            background: none;
        }

        &:first-child{
            display: none;
        }
    }
}

.bg-cover{
    overflow: visible !important;
    margin-bottom: 40px;
    position: relative;
    z-index: 9999;
    .seo-wrap{
        height: 100%;

        .seo-breadcrumb{
            position: absolute;
            z-index: 9999;
            bottom: -60px;
        }
    }
}
.home .seo-breadcrumb{
    display: none;
}
.seo-breadcrumb{
    max-width: 1170px;
    margin: auto;
    line-height: 14px;
    // position: absolute;
    // top: 43vw;
    // z-index: 9999;

    // @media only screen and (max-width: 1440px){
    //     top: 45vw;
    // }

    // @media #{$smaller-menu}{
    //     top: 52vw;
    // }
    // @media #{$not-desktop}{
    //     top: 26em;
    //     line-height: 14px;
    // }
    span{
        font-size: 0;
        a{
            color: var(--color__green);
            font-size: 14px;
            line-height: 14px;
            font-family: 'futura-m';

            &:hover{
                color: var(--color__red);
            }

            &:after{
                content: "\f0da";
                font-family: fontAwesome;
                padding: 0 13px;
                color: #727272 !important;
            }
        }
        strong{
            font-size: 14px;
            color: var(--color__green);
            font-family: 'futura-m';
            // font-weight: 700;
        }
    }
    
}
   /* ####################################################################################

                                    HOME

/* ####################################################################################*/
h2.vc_custom_heading.custom-title  {
    font-weight: 400;
    margin-bottom: 70px;
    margin-top: 30px;

    @media #{$phone}{
        font-size: 30px !important;
    }
}
h2.vc_custom_heading.custom-title:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 7px;
    background: #3a8e88;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%);
}
.custom-title>.vc_column_container{
    padding-bottom: 20px;
    margin-bottom: 40px;
}
.home-des{
    @media #{$larger-menu}{
        padding: 0 15px;
    }
}
h2.wpb_heading.wpb_singleimage_heading{
    font-weight: 400;
}

.homework{

     .wpb_single_image{
        display: flex;
        flex-direction: column;
    
        h2.wpb_heading.wpb_singleimage_heading{
            font-size: 30px;
            order:2;
            margin-top: 20px;
            color: var(--color__black);
            font-weight: 400;
            transition: 0.3s all;
            text-align: center;

            @media #{$phone}{
                font-size: 20px;
            }
        }
    }
}


.homework .wpb_single_image.wpb_content_element {
    position: relative;
}
// .homework a.vc_single_image-wrapper:before {
//     content: '';
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: rgba(0,0,0,0.5);
//     transition: 0.3s all,
// }

.homework .wpb_single_image:hover {
    .wpb_heading{
        color: var(--color__red);
        border-color: var(--color__red);
        transition: 0.3s all;
    }
     .vc_single_image-wrapper:before{
        background-color: rgba(0,0,0,0.7);
        transition: 0.3s all;
     }
 }

 @media #{$not-desktop}{
    .homework figure{
        position: relative;
    }
 }

.home-explore-sec {
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: visible !important;
    height: 40vw;
    min-height: 300px;
    max-height: 700px;

    @media #{$not-desktop}{
        margin-top: 170px;
    }

    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0,0,0,0.8) 5%,rgba(0,0,0,0) 50%);
        top: 0;
        left: 0;

         @media #{$not-desktop}{
            background: linear-gradient(rgba(0,0,0,0.8) 20%,rgba(0,0,0,0) 85%);
        }

    }

    p{
        color: white;
    }
}
h2.vc_custom_heading.custom-title2 {
    margin-bottom: 30px;
}

 .homeexplore-row {
     span.vc_sep_line {
        width: 80px !important;
    }

    @media #{$not-desktop}{
            position: absolute;
            top: -130px;
        }

    .vc_col-sm-4{
        position: relative;
        top: -60px;

        @media #{$not-desktop}{
            top: -10px;
        }

        .vc_column-inner{
            padding: 30px 40px 5px;
        }
    }
    .wpb_text_column {
        margin-bottom: 20px;
    }
    .vc_col-sm-8{
        @media #{$not-phone} {
            padding-left: 50px;
        }
    }
}

.home .homeexplore-row{
     a{
        font-size: 25px;
        text-shadow: 2px 2px 5px black;

        &:hover{
            color: var(--color__red);
        }
    }
}

.edu-grid {
    h4 {
        font-size: 20px;
        height: 60px;
        font-weight: 400;

        @media #{$not-desktop}{
            height: unset;
        }
    }
    p:nth-child(2){
        font-size: 16px;
        line-height: 20px;
        height: 120px;
        text-align: left;

        @media #{$not-desktop}{
            height: unset;
        }
    }
    a.vc_btn3 {
        border: 1px solid var(--color__grey);
        font-family: 'futura-m';
        padding: 5px 20px !important;

        @media #{$not-desktop}{
            padding: 5px 10px !important;
            font-size: 13px;
        }
    }
    .vc_gitem-zone-c{
        border: 1px solid #d0d1d1;
        border-right: none;
        border-top: 0;
    }
    .vc_grid-item:first-child .vc_gitem-zone-c{
            border-left: none;
    }
    
}

.news-grid{
    h4{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    p:nth-child(2){
        font-size: 14px;
        line-height: 20px;
        text-align: left;

        @media #{$not-desktop}{
            font-size: 16px;
        }
    }
    .vc_gitem_row .vc_gitem-col{
        padding-left: 20px
    }
    a.vc_gitem-link.vc_btn3 {
        padding: 5px 20px !important;
        border: 1px solid rgba(255,255,255,0.3);
    }
    .vc_btn3-container.vc_btn3-center {
        margin-bottom: 0;
    }
    .vc_grid-item-mini{
        transition: box-shadow 0.3s;
    }
    .vc_grid-item-mini:hover {
        box-shadow: 5px 5px 50px rgba(0,0,0,0.7);
        transition: box-shadow 0.3s;
    }
    .vc_gitem-post-data-source-post_date{
        font-family: futura-m;
        font-size: 13px;
    }

    @media #{$not-desktop}{
        .vc_pageable-slide-wrapper{
            margin-right: 0 !important;
        }
            .vc_grid-item{
                padding-right: 0 !important;
            }
    }

    @media #{$phone}{
        .vc_gitem-animated-block.vc_gitem-animate{
            float: none;
            margin: auto;
            padding-top: 30px;
        }
        .vc_gitem-zone.vc_gitem-zone-c{
            width: 100%;
            float: none;
        }

    }
}


.custom-btn a.vc_general {
    padding: 6px 20px!important;
    font-family: futura-m;
    font-weight: 600;
    border: 1px solid;
}

.engage-row {
    margin-top: 0;

    @media #{$not-desktop} {
        margin-top: 140px;
    }

    h2.vc_custom_heading.custom-title2{
        margin-bottom: 0px;

        @media #{$phone}{
            font-size: 40px !important;
        }
    }
    h4{
        font-size: 35px;
        color: white;
        margin-top: 0;
        font-weight: 400;

        @media #{$phone}{
            font-size: 25px;
        }
    }
    .vc_col-sm-4 p{
        display: none;
    }
}

.home-team{
    margin-bottom: 50px;
    .wpb_single_image.wpb_content_element {
        display: flex;
        flex-direction: column;

        h2{
            order: 2;
            text-align: center;
            font-size: 25px;
            transition: all 0.3s;
            margin-top: 6vw;

            @media(min-width: 1281px){
                margin-top: 80px;
            }
        }
        figure.wpb_wrapper.vc_figure {
            max-width: 450px;
            margin: auto;
        }
    }
    h2.vc_custom_heading{
        font-weight: 400;
    }
    .vc_col-sm-6:hover{
        h2.vc_custom_heading{
            color: #3a8e88 !important;
            transition: all 0.3s;
        }
    }
}

.public-sec{
    margin-top: 50px;
}

.public-carousel {
    margin: 20px 0 40px;

    @media #{$phone}{
        .vc_column_container>.vc_column-inner{
            padding: 0;
        }
    }
    button.slick-arrow {
        border: 1px solid #17252a;
        border-radius: 50%;

        @media #{$phone}{
            display: none !important;
        }
    }
    button.slick-prev{
        @media #{$phone}{
            left: 15px;
            z-index: 99;
        }

        &:before{
            position: relative;
            top: -1px;
            left: -1px;
        }
    }
    button.slick-next{
        @media #{$phone}{
            right: 15px;
            z-index: 99;
        }

        &:before{
            position: relative;
            top: -1px;
            right: -1px;
        }
    }

}

   /* ####################################################################################

                                    ABOUT

/* ####################################################################################*/
@import "about";
@import "work";
@import "contact";
@import "shop";
@import "education";
@import "admend";